import { createContext, useState } from "react";

export const ProjectContext = createContext(null);

const ProjectContextProvider = ({ children }) => {
  const [projectId, setProjectId] = useState("");
  //const [userId, setUserId] = useState("");
  const [projectName, setProjectName] = useState("");
  const [client, setClient] = useState("");
  const [projectUrl, setProjectUrl] = useState("");
  const [platformType, setPlatformType] = useState("");
  const [activityArea, setActivityArea] = useState("");
  const [country, setCountry] = useState("");
  const [technology, setTechnology] = useState("");
  const [analyticsPlatform, setAnalyticsPlatform] = useState("");
  const [encapsulator, setEncapsulator] = useState("");
  const [information, setInformation] = useState("");
  const [active, setActive] = useState("");

  return (
    <ProjectContext.Provider
      value={{
        projectId,
        setProjectId,
        // userId,
        // setUserId,
        projectName,
        setProjectName,
        client,
        setClient,
        projectUrl,
        setProjectUrl,
        platformType,
        setPlatformType,
        activityArea,
        setActivityArea,
        country,
        setCountry,
        technology,
        setTechnology,
        analyticsPlatform,
        setAnalyticsPlatform,
        encapsulator,
        setEncapsulator,
        information,
        setInformation,
        active,
        setActive,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;
