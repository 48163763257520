import React, { useState, useEffect } from "react";
import instance from "../axios/global";
import { useNavigate, useParams } from "react-router-dom";
import { TextInput, Textarea, Select, Button, Modal } from "@mantine/core";
import { useForm } from "@mantine/form";
import cross from "../assets/cross.svg";
import add from "../assets/add.svg";
import right from "../assets/drop-right.svg";

const EditEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [parameters, setParameters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [parameterToDelete, setParameterToDelete] = useState(null);

  const form = useForm({
    initialValues: {
      name: "",
      //type: '',
      trigger: "",
      tags: "",
      description: "",
      parameters: [],
      keys: "",
    },
    validate: {
      name: (value) =>
        value.length === 0 ? "Veuillez remplir ce champs" : null,
      //type: (value) => value.length === 0 ? "Veuillez remplir ce champs" : null,
      trigger: (value) =>
        value.length === 0 ? "Veuillez remplir ce champs" : null,
      tags: (value) =>
        value.length === 0 ? "Veuillez remplir ce champs" : null,
    },
  });

  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/event/${id}`);
        setEvent(res.data);
        console.log("sall" + JSON.stringify(event));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };

    const fetchParameters = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/parameter/event/${id}`);
        setParameters(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };

    fetchEvent();
    fetchParameters();
  }, [id]);

  useEffect(() => {
    if (event) {
      form.setValues((currentValues) => ({
        ...currentValues,
        name: event.name || "",
        //type: event.type || '',
        trigger: event.trigger || "",
        tags: event.tags || "",
        description: event.description || "",
        parameters: parameters.map((param) => ({
          id: param.id || null,
          parameterName: param.parameterName || "",
          datatype: param.datatype || "",
          value: param.value || "",
          parameterComments: param.parameterComments || "",
        })),
      }));
    }
  }, [event, parameters]);

  const addParameter = () => {
    const newParameter = {
      parameterName: "",
      datatype: "",
      value: "",
      parameterComments: "",
      isNew: true,
    };
    form.setValues({
      ...form.values,
      parameters: [...form.values.parameters, newParameter],
    });
  };

  const openDeleteModal = (indexToRemove) => {
    setParameterToDelete(indexToRemove);
    setOpened(true);
  };

  const confirmDeleteParameter = async () => {
    const indexToRemove = parameterToDelete;
    const parameterToRemove = form.values.parameters[indexToRemove];
    if (parameterToRemove.id) {
      try {
        await instance.delete(`/parameter/delete/${parameterToRemove.id}`);
        console.log(`Paramètre supprimé: ${parameterToRemove.parameterName}`);
      } catch (error) {
        console.error("Erreur lors de la suppression du paramètre :", error);
      }
    }
    form.setValues({
      ...form.values,
      parameters: form.values.parameters.filter(
        (_, index) => index !== indexToRemove
      ),
    });
    console.log("Form values after removing parameter:", form.values);
    setOpened(false);
    setParameterToDelete(null);
  };

  const handleUpdateGenericEvent = async () => {
    console.log("Début de la soumission du formulaire");

    if (form.isValid()) {
      try {
        const resEvent = await instance.put(`/event/update/${id}`, {
          name: form.values.name,
          isGeneric: true,
          //type: form.values.type,
          trigger: form.values.trigger,
          tags: form.values.tags,
          description: form.values.description,
        });

        console.log("Response from event update:", resEvent);

        const eventId = resEvent.data.eventId || id;
        console.log("Event ID:", eventId);

        const parameters = form.values.parameters;

        for (const parameter of parameters) {
          if (parameter.id) {
            await instance.put(`/parameter/update/${parameter.id}`, {
              parameterName: parameter.parameterName,
              datatype: parameter.datatype,
              value: parameter.value,
              parameterComments: parameter.parameterComments,
              eventId: eventId,
            });
            console.log(`Paramètre mis à jour: ${parameter.parameterName}`);
          } else {
            await instance.post(`/parameter/create`, {
              parameterName: parameter.parameterName,
              datatype: parameter.datatype,
              value: parameter.value,
              parameterComments: parameter.parameterComments,
              eventId: eventId,
            });
            console.log(`Nouveau paramètre créé: ${parameter.parameterName}`);
          }
        }

        form.reset();
        navigate(`/events-generique`);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="create-event">
      <div className="titles">
        <h1>Modifier mon event générique</h1>
        <h2>
          Modifier mon evenement ne modifiera pas les anciens events générique
          utilisés sur vos projets
        </h2>
      </div>
      <form onSubmit={form.onSubmit(handleUpdateGenericEvent)}>
        <div className="container-params">
          <div className="left">
            <h3>1. Paramètres</h3>
            <h4>Choix des paramètres de mon projet</h4>
          </div>
          <div className="right">
            <div className="input-container">
              <TextInput
                withAsterisk
                label="Nom de event"
                description="Entrez le nom de votre event"
                {...form.getInputProps("name")}
              />
              <TextInput
                withAsterisk
                label="Déclencheur"
                description="Entrez le nom de votre Déclencheur"
                {...form.getInputProps("trigger")}
              />
              {/* <Select
                                withAsterisk
                                label="Type d'event"
                                description="Entrez le type de votre event"
                                data={[
                                    { value: 'View Page', label: 'View Page' },
                                    { value: 'Clic', label: 'Clic' },
                                    { value: 'Form', label: 'Form' },
                                ]}
                                {...form.getInputProps("type")}
                            /> */}
            </div>
            <div className="input-container">
              <Select
                withAsterisk
                label="Tag"
                description="Entrez le tag de votre event"
                data={[
                  { value: "standard", label: "Standard event" },
                  { value: "conversion", label: "Conversion event" },
                  { value: "interaction", label: "Interaction event" },
                ]}
                {...form.getInputProps("tags")}
              />
            </div>
            <Textarea
              placeholder="Description de l'evenement"
              label="Description"
              {...form.getInputProps("description")}
            />
          </div>
        </div>
        <div className="container-params-2">
          <div className="left-2">
            <h3>2. Paramètres associés à l’event</h3>
            <h4>Assigner des paramètres a mon projet</h4>
          </div>
          <div className="right-2">
            {form.values.parameters.length === 0 ? (
              ""
            ) : (
              <div className="label-container">
                <label style={{ marginRight: "1.5rem" }}>
                  Nom du paramètre
                </label>
                <label style={{ marginRight: "3rem" }}>Type de données</label>
                <label style={{ marginRight: "1.5rem" }}>
                  Valeur par défaut
                </label>
                <label>Commentaire</label>
              </div>
            )}
            {form.values.parameters.map((parameter, index) => (
              <div className="parameter-container" key={index}>
                <TextInput
                  withAsterisk
                  // description={`${index + 1}`}
                  style={{ marginRight: "1.5rem" }}
                  value={parameter.parameterName}
                  {...form.getInputProps(`parameters.${index}.parameterName`)}
                />
                <Select
                  withAsterisk
                  // description="Entrez le type de données de votre paramètre"
                  style={{ marginRight: "1rem" }}
                  data={[
                    { value: "string", label: "String" },
                    { value: "integer", label: "Number" },
                    { value: "boolean", label: "Boolean" },
                  ]}
                  {...form.getInputProps(`parameters.${index}.datatype`)}
                  value={parameter.datatype}
                  onChange={(value) => {
                    form.setFieldValue(`parameters.${index}.datatype`, value);
                    let defaultValue;
                    switch (value) {
                      case "string":
                        defaultValue = "^[(a-zA-Z0-9-_, )+]*$";
                        break;
                      case "integer":
                        defaultValue = "^([0-9\\s,-])*$";
                        break;
                      case "boolean":
                        defaultValue = "^(true|false)$";
                        break;
                      default:
                        defaultValue = "";
                    }
                    form.setFieldValue(
                      `parameters.${index}.value`,
                      defaultValue
                    );
                  }}
                />
                <img
                  src={right}
                  style={{ marginBottom: "0.85rem" }}
                  alt="arrow-right"
                />
                <TextInput
                  withAsterisk
                  style={{ marginLeft: "1rem" }}
                  // description="Entrez la valeur par défaut"
                  value={parameter.value}
                  {...form.getInputProps(`parameters.${index}.value`)}
                />
                <TextInput
                  // description="Entrez votre commentaire"
                  value={parameter.parameterComments}
                  style={{ marginLeft: "1.5rem" }}
                  {...form.getInputProps(
                    `parameters.${index}.parameterComments`
                  )}
                />
                <div className="container-cross" style={{ marginLeft: "10px" }}>
                  <img
                    src={cross}
                    alt="delete"
                    type="button"
                    style={{ cursor: "pointer", marginBottom: "0.85rem" }}
                    onClick={() => openDeleteModal(index)}
                  />
                </div>
              </div>
            ))}
            <div className="container-btn">
              <img
                src={add}
                alt="add"
                type="button"
                style={{ cursor: "pointer", marginTop: "20px" }}
                onClick={addParameter}
              />
            </div>
          </div>
          <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title="Confirmer la suppression"
          >
            <p>
              Êtes-vous sûr de vouloir supprimer ce paramètre ? Cette action est
              irréversible.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Button
                variant="outline"
                color="gray"
                onClick={() => setOpened(false)}
              >
                Annuler
              </Button>
              <Button color="red" onClick={confirmDeleteParameter}>
                Supprimer
              </Button>
            </div>
          </Modal>
        </div>

        <div className="div-button">
          <Button type="submit">Modifier mon event générique</Button>
        </div>
      </form>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Confirmer la suppression"
      >
        <p>
          Êtes-vous sûr de vouloir supprimer ce paramètre ? Cette action est
          irréversible.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Button
            variant="outline"
            color="gray"
            onClick={() => setOpened(false)}
          >
            Annuler
          </Button>
          <Button color="red" onClick={confirmDeleteParameter}>
            Supprimer
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default EditEvent;
