import React from "react";
import { useNavigate } from "react-router-dom";
import back from "../assets/back.svg";

const BackButton = ({ name }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <button onClick={handleBack} className="back-button">
      <img src={back} alt="Retour" /> {name}
    </button>
  );
};

export default BackButton;
