import React, { useEffect, useState, useContext } from "react";
import instance from "../axios/global";
import { Link, useNavigate } from "react-router-dom";
import {
  IconDotsVertical,
  IconCopyPlus,
  IconSettingsAutomation,
} from "@tabler/icons-react";
import { Menu, TextInput } from "@mantine/core";

const Homepage = ({ onProjectChange }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Nouvel état pour la recherche
  const navigate = useNavigate();

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const res = await instance.get("/project/all");
      setProjects(res?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Erreur lors de la récupération des projets :", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleDuplicateProject = async (projectId) => {
    try {
      const response = await instance.post(`/project/duplicate/${projectId}`);
      const duplicatedProject = response.data;
      console.log("Projet dupliqué avec succès :", duplicatedProject);
      fetchProjects(); // Recharger les projets après duplication réussie
    } catch (error) {
      console.error("Erreur lors de la duplication du projet :", error);
    }
  };

  const handleProjectClick = (projectName) => {
    onProjectChange(projectName);
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const ProjectStatus = ({ status }) => {
    let statusClass = "";
    switch (status) {
      case "En cours d'implementation":
        statusClass = "status-en-cours";
        break;
      case "Configuré":
        statusClass = "status-configure";
        break;
      case "À configurer":
        statusClass = "status-to-configure";
        break;
      case "Cloturé":
        statusClass = "status-cloture";
      case "En attente":
        statusClass = "status-en-attente";
        break;
      default:
        statusClass = "";
    }
    return <p className={statusClass}>{status}</p>;
  };

  // Filtrer les projets en fonction de la valeur de recherche
  const filteredProjects = projects.filter(
    (project) =>
      project.client.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.projectName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="contrainer-project-cards">
      <div className="homepage">
        <div className="header-homepage">
          <h1>Projets</h1>
          <h2>Retrouvez tous vos projets</h2>

          <div>
            <TextInput
              className="search-bar"
              label="Recherche"
              placeholder="Entrez le nom du projet ou le client"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.currentTarget.value)}
            />
          </div>
        </div>

        {loading ? (
          <p>Chargement des projets...</p>
        ) : Array.isArray(filteredProjects) && filteredProjects.length > 0 ? (
          <div className="project-cards">
            {filteredProjects.map((project) => (
              <div className="card" key={project.id}>
                <div className="title-menu">
                  <div>
                    <Link
                      className="container-links"
                      to={`/project/${project.id}`}
                      onClick={() => handleProjectClick(project.projectName)}
                    >
                      <span>{project.client}</span>
                      <span className="title2">{project.projectName}</span>
                    </Link>
                  </div>
                  <div>
                    <Menu shadow="md" width={200} style={{ cursor: "pointer" }}>
                      <Menu.Target>
                        <IconDotsVertical size={16} />
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Label>Actions</Menu.Label>
                        <Menu.Item
                          icon={<IconCopyPlus size={14} />}
                          onClick={() => handleDuplicateProject(project.id)}
                        >
                          Dupliquer
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => navigate(`/parametres/${project.id}`)}
                          icon={<IconSettingsAutomation size={14} />}
                        >
                          Paramètres
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </div>
                </div>
                <Link
                  className="container-links"
                  to={`/project/${project.id}`}
                  onClick={() => handleProjectClick(project.id)}
                >
                  <ProjectStatus status={project.status} />
                  <ul>
                    <li>{project.platform_type}</li>
                    <li>{project.projectUrl}</li>
                    <li>{project.technology}</li>
                    <li>{project.analytics_platform}</li>
                  </ul>
                  <span className="created">
                    Crée le {formatDate(project?.createdAt)}
                  </span>
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <p>Aucun projet trouvé.</p>
        )}
      </div>
    </div>
  );
};

export default Homepage;
