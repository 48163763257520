import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import CreateProject from "./CreateProject";
import Homepage from "./Homepage";
import CreateEvent from "./CreateEvent";
import Sidebar from "../components/Sidebar";
import GenericEvent from "./GenericEvent";
import Project from "./Project";
import CustomEvent from "./CustomEvent";
import ProjectContextProvider from "../context/projectContext";
import SettingsProject from "./SettingsProject";
import TrackingPlan from "./TrackingPlan";
import ConfigEvent from "./ConfigEvent";
import EditEvent from "./EditEvent";
import PublicTrackingPlan from "./PublicTrackingPlan";
import RequireAuth from "../hooks/RequireAuth";

function Accueil() {
  const [selectedProject, setSelectedProject] = useState(null);
  const location = useLocation(); 


  const handleProjectChange = (projectId) => {
    setSelectedProject(projectId);
    localStorage.setItem("selectedProject", projectId);
  };
  
  

  const handleDeselectProject = () => {
    setSelectedProject(null);
    localStorage.removeItem("selectedProject");
  };

  useEffect(() => {
    const storedProject = localStorage.getItem("selectedProject");
    if (storedProject) {
      setSelectedProject(storedProject);
    }
  }, []);
  

  // Clear selectedProject when navigating to Home, Create Project, or Generic Events
  useEffect(() => {
    const pathsToClearProject = ["/home", "/creer-projet", "/events-generique"];
    if (pathsToClearProject.includes(location.pathname)) {
      handleDeselectProject();
    }
  }, [location.pathname]);

  return (
    <div className="accueil">
      <ProjectContextProvider>
        <Sidebar selectedProject={selectedProject} onHomepageClick={handleDeselectProject} />
        <div className="content">
          <section className="section-switch-nav">
            <div className="div-switch">
              <Routes>
                {/* Routes publiques */}
                <Route path="/public-plan-tracking/:id" element={<PublicTrackingPlan />} />

                {/* Routes protégées */}
                <Route element={<RequireAuth />}>
                  <Route path="/home" element={<Homepage onProjectChange={handleProjectChange} />} />
                  <Route path="/events-generique" element={<GenericEvent />} />
                  <Route path="/creer-event" element={<CreateEvent />} />
                  <Route path="/creer-projet" element={<CreateProject />} />
                  <Route path="/project/:projectId" element={<Project />} />
                  <Route path="/edit-event/:id" element={<EditEvent />} />
                  <Route path="/project/:id/add-custom-event" element={<CustomEvent />} />
                  <Route path="/project/:projectId/configurer-event/:id" element={<ConfigEvent />} />
                  <Route path="/plan-tracking/:id" element={<TrackingPlan setSelectedProject={setSelectedProject} />} />
                  <Route path="/parametres/:id" element={<SettingsProject setSelectedProject={setSelectedProject} />} />
                </Route>
              </Routes>
            </div>
          </section>
        </div>
      </ProjectContextProvider>
    </div>
  );
}

export default Accueil;
