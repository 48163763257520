import React, { useState, useEffect } from "react";
import instance from "../axios/global";
import { useNavigate, useParams } from "react-router-dom";
import { TextInput, Textarea, Button, Select, Box } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import cross from "../assets/cross.svg";
import add from "../assets/add.svg";

const SettingsProject = ({ setSelectedProject }) => {
  const { id } = useParams();
  const [project, setProject] = useState("");
  const [loading, setLoading] = useState(false);
  const [opened, { close, open }] = useDisclosure(false);
  const [customFields, setCustomFields] = useState([]);
  const navigate = useNavigate();

  const isValidUrl = (url) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(url);
  };

  console.log(id);
  const form = useForm({
    initialValues: {
      client: "",
      projectName: "",
      platform_type: "",
      projectUrl: "",
      projectUrlPreProd: "",
      activity_area: "",
      country: "",
      encapsulator: "",
      technology: "",
      analytics_platform: "",
      status: "",
      custom_fields: [],
    },
    validate: {
      client: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      projectName: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      platform_type: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      country: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      technology: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      analytics_platform: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      projectUrl: (value) => {
        if (value.length === 0) {
          return "Veuillez remplir ce champs";
        } else {
          return !isValidUrl(value) ? "Veuillez entrer une URL valide" : null;
        }
      },
      encapsulator: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      activity_area: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      status: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
    },
  });
  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/project/${id}`);
        const projectData = res?.data || {};
        form.setValues({
          client: projectData.client || "",
          projectName: projectData.projectName || "",
          projectUrl: projectData.projectUrl || "",
          projectUrlPreProd: projectData.projectUrlPreProd || "",
          country: projectData.country || "",
          technology: projectData.technology || "",
          analytics_platform: projectData.analytics_platform || "",
          encapsulator: projectData.encapsulator || "",
          activity_area: projectData.activity_area || "",
          platform_type: projectData.platform_type || "",
          information: projectData.information || "",
          status: projectData.status || "",
          custom_fields: Array.isArray(projectData.custom_fields)
            ? projectData.custom_fields
            : [],
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProject();
  }, [id]);

  const addCustomField = () => {
    const newCustomFields = [
      ...form.values.custom_fields,
      { key: "", value: "" },
    ];
    form.setValues({ custom_fields: newCustomFields });
  };

  const removeCustomField = (index) => {
    const newCustomFields = form.values.custom_fields.filter(
      (_, i) => i !== index
    );
    form.setValues({ custom_fields: newCustomFields });
  };

  const handleCustomFieldChange = (index, field, value) => {
    const newCustomFields = [...form.values.custom_fields];
    newCustomFields[index][field] = value;
    form.setValues({ custom_fields: newCustomFields });
  };

  const handleUpdateProjectData = async () => {
    console.log("Début de la soumission du formulaire");

    if (form.isValid()) {
      try {
        console.log(
          "Valeurs des champs personnalisés avant l'envoi : ",
          form.values.custom_fields
        );

        const res = await instance.put(`/project/update/${id}`, {
          client: form.values.client,
          projectName: form.values.projectName,
          projectUrl: form.values.projectUrl,
          projectUrlPreProd: form.values.projectUrlPreProd,
          country: form.values.country,
          technology: form.values.technology,
          analytics_platform: form.values.analytics_platform,
          encapsulator: form.values.encapsulator,
          activity_area: form.values.activity_area,
          platform_type: form.values.platform_type,
          information: form.values.information,
          status: form.values.status,
          custom_fields: form.values.custom_fields,
        });
        console.log(
          "🚀 ~ file: CreateProject.js:97 ~ handleSubmitProjectData ~ res:",
          res
        );

        // toast.success("Votre projet a bien été crée.", {
        //   position: toast.POSITION.TOP_CENTER,
        //   className: "toast-message",
        // });
        form.reset();
        navigate(`/home`);
      } catch (error) {
        // toast.error(error.response.data.message, {
        //   position: toast.POSITION.TOP_LEFT,
        // });
        console.log("Error:", error);
      }
    }
    //Or the other way
  };

  const handleSubmitDelete = async () => {
    try {
      const deleteProject = await instance.delete(`project/delete/${id}`);
      console.log("Suppression du projet réussie : ", deleteProject.data);
      //   toast.success("Votre projet a bien été supprimé.", {
      //     position: toast.POSITION.TOP_CENTER,
      //     className: "toast-message",
      //   });
      setSelectedProject(null);
      navigate(`/home`);
    } catch (error) {
      // Gérez les erreurs ici
      console.error("Erreur lors de la suppression: ", error);

      // Affichez une notification d'erreur
      //   toast.error(
      //     error.response.data.msg ||
      //       "Une erreur s'est produite lors de la suppresion du projet",
      //     {
      //       position: toast.POSITION.TOP_LEFT,
      //     }
      //   );
    }
  };
  console.log("Valeurs des champs personnalisés:", form.values.custom_fields);
  return (
    <div className="create-project">
      <div className="titles-btn">
        <div>
          <h1>Modifier mon projet</h1>
          <h2>
            Entrez les donneés necessaires a la modification de votre projet
          </h2>
        </div>
        <Button style={{ background: "#dd5c64" }} onClick={open}>
          supprimer mon projet
        </Button>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        title="Supprimer mon projet"
      >
        <div className="modal">
          <p style={{ marginBottom: "2rem", fontSize: "0.875rem" }}>
            Êtes vous sur de vouloir supprimer votre projet ?
          </p>
          <div
            className="btn-div"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Button
              className="cancel-btn"
              color="gray"
              onClick={close}
              style={{ marginRight: "1rem" }}
            >
              annuler
            </Button>
            <Button
              className="delete-btn"
              color="red"
              onClick={handleSubmitDelete}
            >
              supprimer
            </Button>
          </div>
        </div>
      </Modal>
      <form onSubmit={form.onSubmit(handleUpdateProjectData)}>
        <div className="container-params">
          <div className="left">
            <h3>1. Paramètres</h3>
            <h4>Choix des paramètres de mon projet</h4>
          </div>

          <div className="right">
            <div className="input-container">
              <TextInput
                withAsterisk
                label="Nom du client"
                value={project.client}
                {...form.getInputProps("client")}
              />
              <TextInput
                withAsterisk
                label="Nom du projet"
                value={project.projectName}
                {...form.getInputProps("projectName")}
              />
            </div>
            <div className="input-container">
              <TextInput
                withAsterisk
                label="Url du projet"
                value={project.projectUrl}
                {...form.getInputProps("projectUrl")}
              />
              <TextInput
                label="Url de la preprod du projet"
                value={project.projectUrlPreProd}
                {...form.getInputProps("projectUrlPreProd")}
              />
            </div>
            <div className="input-container">
              <Select
                withAsterisk
                label="Pays"
                searchable
                placeholder="Entrez le Pays"
                data={[
                  { value: "france", label: "France" },
                  { value: "espagne", label: "Espagne" },
                  { value: "etats-unis", label: "États-Unis" },
                  { value: "royaume-Uni", label: "Royaume-Uni" },
                  { value: "canada", label: "Canada" },
                  { value: "allemagne", label: "Allemagne" },
                  { value: "nouvelle-zelande", label: "Nouvelle Zélande" },
                ]}
                {...form.getInputProps("country")}
              />
              <Select
                withAsterisk
                label="Technologie utilisée"
                placeholder="Entrez la technologie utilisée"
                searchable
                data={[
                  { value: "custom", label: "Custom" },
                  { value: "shopify", label: "Shopify" },
                  { value: "wordpress", label: "WordPress" },
                ]}
                {...form.getInputProps("technology")}
              />
            </div>
            <div className="input-container">
              <Select
                withAsterisk
                label="Tag Manager"
                placeholder="Entrez votre Tag Manager"
                searchable
                data={[
                  { value: "custom", label: "Custom" },
                  { value: "gtm", label: "GTM" },
                  { value: "tag_commander", label: "Tag Commander" },
                  { value: "tealium", label: "Tealium" },
                ]}
                {...form.getInputProps("encapsulator")}
              />
              <Select
                withAsterisk
                label="Type de plateforme"
                placeholder="Entrez le type de plateforme"
                searchable
                data={[
                  { value: "android", label: "Android" },
                  { value: "ios", label: "iOS" },
                  { value: "web", label: "Web" },
                ]}
                {...form.getInputProps("platform_type")}
              />
            </div>
            <div className="input-container">
              <Select
                withAsterisk
                label="Secteur d'activité"
                placeholder="Entrez le Secteur d'activité"
                searchable
                data={[
                  { value: "alimentation", label: "Alimentation" },
                  { value: "automobile", label: "Automobile" },
                  { value: "beauté", label: "Beauté" },
                  { value: "éducation", label: "Éducation" },
                  { value: "énergie", label: "Énergie" },
                  { value: "finance", label: "Finance" },
                  { value: "immobilier", label: "Immobilier" },
                  { value: "Industrie", label: "Industrie" },
                  { value: "médias", label: "Médias" },
                  { value: "mode", label: "Mode" },
                  { value: "santé", label: "Santé" },
                  { value: "technologie", label: "Technologie" },
                  { value: "tourisme", label: "Tourisme" },
                  { value: "transport", label: "Transport" },
                ]}
                {...form.getInputProps("activity_area")}
              />
              <Select
                withAsterisk
                label="Plateforme analytics"
                searchable
                placeholder="Entrez le nom de votre Plateforme analytics"
                data={[
                  { value: "AdobeAnalytics", label: "Adobe Analytics" },
                  { value: "GoogleAnalytics", label: "Google Analytics" },
                  { value: "PianoAnalytics", label: "Piano Analytics" },
                  { value: "PiwikPro", label: "Piwik Pro" },
                  { value: "custom", label: "Custom" },
                ]}
                {...form.getInputProps("analytics_platform")}
              />
            </div>
            <div className="input-container">
              <Select
                withAsterisk
                label="Statut"
                placeholder="Statut du projet"
                searchable
                data={[
                  { value: "À configurer", label: "À configurer" },
                  {
                    value: "En cours d'implementation",
                    label: "En cours d'implementation",
                  },
                  { value: "Configuré", label: "Configuré" },
                  { value: "En attente", label: "En attente" },
                  { value: "Cloturé", label: "Cloturé" },
                ]}
                {...form.getInputProps("status")}
              />
            </div>
            <Textarea
              placeholder="Informations"
              label="Informations"
              autosize
              minRows={3}
              {...form.getInputProps("information")}
            />
            <Box mt="md">
              <h3 style={{ marginBottom: "15px" }}>Champs personnalisés</h3>
              {form.values.custom_fields &&
              form.values.custom_fields.length > 0 ? (
                form.values.custom_fields.map((field, index) => (
                  <div
                    className="custom-fields-container"
                    key={index}
                    mt="xs"
                    position="apart"
                  >
                    <div className="name-cross">
                      <TextInput
                        placeholder="Nom du champ"
                        value={field.key || ""}
                        onChange={(event) =>
                          handleCustomFieldChange(
                            index,
                            "key",
                            event.currentTarget.value
                          )
                        }
                      />
                      <img
                        src={cross}
                        alt="delete"
                        type="button"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeCustomField(index)}
                      />
                    </div>
                    <Textarea
                      autosize
                      placeholder="Valeur du champ"
                      value={field.value || ""}
                      onChange={(event) =>
                        handleCustomFieldChange(
                          index,
                          "value",
                          event.currentTarget.value
                        )
                      }
                    />
                  </div>
                ))
              ) : (
                <p>Aucun champ personnalisé ajouté.</p>
              )}
              <div className="container-add-btn">
                <img
                  src={add}
                  alt="add"
                  type="button"
                  style={{ cursor: "pointer", marginTop: "20px" }}
                  onClick={addCustomField}
                />
              </div>
            </Box>
          </div>
        </div>
        <div className="container-params">
          <div className="left">
            {/* <h3>2. Choix des évenements</h3>
              <h4>Assigner des evenements a mon projet</h4> */}
          </div>
          <div className="right">
            {/* <div className='container-chip'>
              {loading ? (
              <p>Chargement en cours...</p>
              ) : (
              allGenericEvents && Array.isArray(allGenericEvents) && allGenericEvents.length > 0 ? (
              allGenericEvents.map((event) => (
              <Chip
              className='chip'
              color='dark'
              key={event.id}
              checked={selectedChips.includes(event.id)}
              onChange={() => handleChipClick(event.id)}
              >
              {event.name}
              </Chip>
              ))
              ) : (
              <p>Aucune donnée disponible</p>
              )
              )}
        </div> */}
            <div className="div-button">
              <Button type="submit">Modifier projet</Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SettingsProject;
