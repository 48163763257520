import React, { useState, useEffect } from "react";
import instance from "../axios/global";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextInput,
  Textarea,
  Select,
  Group,
  Text,
  useMantineTheme,
  SimpleGrid,
  Image,
  Button,
  Modal,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import cross from "../assets/cross.svg";
import add from "../assets/add.svg";
import close from "../assets/close.svg";
import right from "../assets/drop-right.svg";
import BackButton from "../components/BackButton";

const ConfigEvent = () => {
  const { projectId, id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [parameters, setParameters] = useState([]);
  const [datalayer, setDatalayer] = useState("");
  const [parameterToDelete, setParameterToDelete] = useState(null);
  const [screenshotToDelete, setScreenshotToDelete] = useState(null);
  const [selectedDataType, setSelectedDataType] = useState("");
  const [files, setFiles] = useState([]);
  const [screenshots, setScreenshots] = useState([]);
  const [isManualEdit, setIsManualEdit] = useState(false);
  const [event, setEvent] = useState({
    name: "",
    //type: "",
    trigger: "",
    priority: "",
    description: "",
    url_test: "",
    page_datalayer: "",
    status: "",
    comments: "",
    keys: "",
  });

  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/event/${id}`);
        setEvent(res?.data);
        setDatalayer(res?.data.datalayer);
        console.log("event logs" + res?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };

    const fetchParameters = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/parameter/event/${id}`);
        setParameters(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };

    const fetchScreenshots = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/image/all/${id}`);
        console.log(res.data);
        setScreenshots(res?.data.allScreenshots);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };

    fetchEvent();
    fetchParameters();
    fetchScreenshots();
  }, [id]);

  useEffect(() => {
    if (event) {
      form.setValues({
        name: event.name,
        //type: event.type,
        trigger: event.trigger,
        priority: event.priority,
        description: event.description,
        url_test: event.url_test,
        page_datalayer: event.page_datalayer,
        comments: event.comments,
        status: event.status,
        keys: event.keys,
        parameters:
          parameters.length > 0
            ? parameters.map((param) => ({
                id: param.id,
                parameterName: param.parameterName,
                datatype: param.datatype,
                value: param.value,
                parameterComments: param.parameterComments,
              }))
            : [],
      });
    }
  }, [event, parameters]);

  const isValidUrl = (url) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(url);
  };

  const form = useForm({
    initialValues: {
      name: event.name,
      //type: event.type,
      trigger: event.trigger,
      priority: event.priority,
      description: event.description,
      url_test: event.url_test,
      page_datalayer: event.page_datalayer,
      comments: event.comments,
      status: event.status,
      keys: event.keys,
      parameters:
        parameters.length > 0
          ? parameters.map((param) => ({
              id: param.id,
              parameterName: param.parameterName,
              datatype: param.datatype,
              value: param.value,
              parameterComments: param.parameterComments,
            }))
          : [],
      ecommerce: {
        item_list_id: "",
        item_list_name: "",
        currency: "",
        value: "",
        shipping_tier: "",
        payment_type: "",
        transaction_id: "",
        affiliation: "",
        tax: "",
        shipping: "",
      },
    },
    validate: {
      name: (value) => {
        return !value || value.length === 0
          ? "Veuillez remplir ce champs"
          : null;
      },
      // type: (value) => {
      //   return !value || value.length === 0 ? "Veuillez remplir ce champs" : null;
      // },
      trigger: (value) => {
        return !value || value.length === 0
          ? "Veuillez remplir ce champs"
          : null;
      },
      priority: (value) => {
        return !value || value.length === 0
          ? "Veuillez remplir ce champs"
          : null;
      },
      page_datalayer: (value) => {
        return !value || value.length === 0
          ? "Veuillez remplir ce champs"
          : null;
      },
      url_test: (value) => {
        if (value.length === 0) {
          return "Veuillez remplir ce champs";
        } else {
          return !isValidUrl(value) ? "Veuillez entrer une URL valide" : null;
        }
      },
      status: (value) => {
        return !value || value.length === 0
          ? "Veuillez remplir ce champs"
          : null;
      },
    },
  });

  const addParameter = () => {
    const newParameter = {
      parameterName: "",
      datatype: "",
      value: "",
      parameterComments: "",
      isNew: true,
    };
    form.setValues({
      ...form.values,
      parameters: [...form.values.parameters, newParameter],
    });
  };

  const confirmDeleteParameter = async () => {
    const indexToRemove = parameterToDelete;
    const parameterToRemove = form.values.parameters[indexToRemove];
    console.log("Parameter to remove:", parameterToRemove);

    if (parameterToRemove.id) {
      try {
        await instance.delete(`/parameter/delete/${parameterToRemove.id}`);
        console.log(`Paramètre supprimé: ${parameterToRemove.parameterName}`);
      } catch (error) {
        console.error("Erreur lors de la suppression du paramètre :", error);
      }
    }

    form.setValues({
      ...form.values,
      parameters: form.values.parameters.filter(
        (_, index) => index !== indexToRemove
      ),
    });

    console.log("Form values after removing parameter:", form.values);
    setOpened(false);
    setParameterToDelete(null);
  };

  const openDeleteModal = (indexToRemove) => {
    setParameterToDelete(indexToRemove);
    setOpened(true);
  };

  //   const handleFileUpload = async (files) => {
  //     console.log("Files added:", files);
  //     setLoading(true);
  //     try {
  //         const formData = new FormData();
  //         formData.append('eventId', id);
  //         files.forEach(file => {
  //             formData.append('image', file);
  //         });
  //         await instance.post('/image/upload', formData, {
  //             headers: {
  //                 'Content-Type': 'multipart/form-data'
  //             }
  //         });
  //         setLoading(false);
  //         //navigate('/'); // Rediriger vers la page d'accueil après le téléchargement
  //     } catch (error) {
  //         setLoading(false);
  //         console.error("Error uploading files:", error);
  //     }
  // };

  //   const generateDataLayerCode = () => {
  //     const eventName = event ? event.name : '';
  //     const dataLayerObject = { event: eventName };
  //     form.values.parameters.forEach((param) => {
  //         dataLayerObject[param.parameterName] = param.value;
  //         if (param.parameterComments) {
  //             dataLayerObject[param.parameterName] += ` /* ${param.parameterComments} */`;
  //         }
  //     });
  //     const dataLayerCode = `dataLayer.push(${JSON.stringify(dataLayerObject, null, 4)});`;
  //     console.log(dataLayerCode);
  //     setDatalayer(dataLayerCode);

  // };

  const generateDataLayerCode = () => {
    const eventName = event ? `"${event.name}"` : "";
    let dataLayerObject = { event: eventName };
    let dataLayerCode = "";

    if (event.tags === "e-commerce") {
      const keys = event.keys || {};
      Object.keys(keys).forEach((key) => {
        dataLayerObject[key] = keys[key];
      });

      dataLayerObject.ecommerce = { items: [{}] };

      form.values.parameters.forEach((param) => {
        let value = `"${param.value}"`;
        if (param.parameterComments) {
          value += ` /* ${param.parameterComments} */`;
        }
        dataLayerObject.ecommerce.items[0][param.parameterName] = value;
      });

      dataLayerCode = "dataLayer.push({ ecommerce: null });\n";
      dataLayerCode += "dataLayer.push({\n";
      dataLayerCode += `    "event": ${dataLayerObject.event},\n`;
      dataLayerCode += '    "ecommerce": {\n';

      Object.keys(keys).forEach((key, index, array) => {
        const value = JSON.stringify(dataLayerObject[key]);
        dataLayerCode += `        "${key}": ${value}`;
        if (
          index !== array.length - 1 ||
          dataLayerObject.ecommerce.items.length > 0
        ) {
          dataLayerCode += ",";
        }
        dataLayerCode += "\n";
      });

      if (dataLayerObject.ecommerce.items.length > 0) {
        dataLayerCode += '        "items": [\n';
        dataLayerObject.ecommerce.items.forEach((item, index) => {
          dataLayerCode += "            {\n";
          Object.keys(item).forEach((key, idx, arr) => {
            const value = item[key];
            dataLayerCode += `                "${key}": ${value}`;
            if (idx !== arr.length - 1) {
              dataLayerCode += ",";
            }
            dataLayerCode += "\n";
          });
          dataLayerCode += "            }";
          if (index !== dataLayerObject.ecommerce.items.length - 1) {
            dataLayerCode += ",";
          }
          dataLayerCode += "\n";
        });
        dataLayerCode += "        ]\n";
      }

      dataLayerCode += "    }\n";
      dataLayerCode += "});";
    } else {
      form.values.parameters.forEach((param) => {
        let value = `"${param.value}"`;
        if (param.parameterComments) {
          value += ` /* ${param.parameterComments} */`;
        }
        dataLayerObject[param.parameterName] = value;
      });

      dataLayerCode = "dataLayer.push({\n";
      Object.keys(dataLayerObject).forEach((key, index, array) => {
        const value = dataLayerObject[key];
        dataLayerCode += `    "${key}": ${value}`;
        if (index !== array.length - 1) {
          dataLayerCode += ",";
        }
        dataLayerCode += "\n";
      });
      dataLayerCode += "});";
    }

    console.log(dataLayerCode);
    setDatalayer(dataLayerCode);
  };

  const handleDatalayerChange = (event) => {
    setIsManualEdit(true); // Indique que l'utilisateur a modifié manuellement
    setDatalayer(event.target.value);
    console.log("Updated datalayer:", event.target.value); // Vérifiez que la valeur est bien mise à jour
  };

  const resetToGeneratedDatalayer = () => {
    setIsManualEdit(false); // Réinitialise l'indicateur d'édition manuelle
    generateDataLayerCode(); // Regénère le datalayer automatiquement
    console.log("Datalayer reset to generated code.");
  };

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        src={imageUrl}
        onLoad={() => URL.revokeObjectURL(imageUrl)}
      />
    );
  });

  const handleSubmitUpdateEvent = async () => {
    if (form.isValid()) {
      try {
        const existingParameters = form.values.parameters.filter(
          (param) => !param.isNew
        );
        const newParameters = form.values.parameters.filter(
          (param) => param.isNew
        );

        // Mettre à jour les paramètres existants
        for (const parameter of existingParameters) {
          console.log("voir ic param id" + parameter.id);
          if (parameter.id) {
            // Assurez-vous que l'ID existe avant de faire une mise à jour
            await instance.put(`/parameter/update/${parameter.id}`, {
              parameterName: parameter.parameterName,
              datatype: parameter.datatype,
              value: parameter.value,
              parameterComments: parameter.parameterComments,
            });
          }
        }

        // Créer de nouveaux paramètres
        for (const parameter of newParameters) {
          await instance.post("/parameter/create", {
            parameterName: parameter.parameterName,
            datatype: parameter.datatype,
            value: parameter.value,
            parameterComments: parameter.parameterComments,
            eventId: id,
          });
        }

        // Mettre à jour l'événement
        await instance.put(`/event/update/${id}`, {
          name: form.values.name,
          //type: form.values.type,
          trigger: form.values.trigger,
          priority: form.values.priority,
          description: form.values.description,
          page_datalayer: form.values.page_datalayer,
          url_test: form.values.url_test,
          comments: form.values.comments,
          status: form.values.status,
          datalayer: datalayer,
          active: true,
        });

        // Vérification des fichiers sélectionnés dans le Dropzone
        if (files.length > 0) {
          const formData = new FormData();
          formData.append("eventId", id);
          files.forEach((file) => {
            formData.append("image", file);
          });

          // Envoyer la requête pour télécharger les images
          await instance.post("/image/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          console.log("Images uploaded successfully");
        }

        navigate(`/project/${projectId}`);
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  const openDeleteScreenshotModal = (index) => {
    setOpened(true);
    setScreenshotToDelete(index);
  };

  const confirmDeleteScreenshot = async () => {
    const indexToRemove = screenshotToDelete;
    const screenshotToRemove = screenshots[indexToRemove];
    console.log("Screenshot to remove:", screenshotToRemove);

    try {
      await instance.delete(`/image/delete/${screenshotToRemove.id}`);
      console.log("Screenshot supprimé avec succès");

      // Mettre à jour l'état local après suppression
      setScreenshots((prevScreenshots) =>
        prevScreenshots.filter((_, index) => index !== indexToRemove)
      );

      setOpened(false);
      setScreenshotToDelete(null);
    } catch (error) {
      console.error("Erreur lors de la suppression du screenshot:", error);
    }
  };

  console.log("ici datalayer modifié ?" + datalayer);
  return (
    <div className="config-event">
      <form onSubmit={form.onSubmit(handleSubmitUpdateEvent)}>
        <BackButton name="Revenir au projet" />
        <h1>{event && event.name}</h1>
        <div className="container-params-2">
          <div className="left">
            <h3 style={{ marginTop: "3rem" }}>1. Informations de l’event</h3>
            <h4>Modifier les informations de base de l'event</h4>
          </div>
          <div className="right">
            <div className="input-container">
              <TextInput
                withAsterisk
                label="Nom de event"
                value={event.name}
                description="Entrez le nom de votre event"
                {...form.getInputProps("name")}
              />
              {/* <Select
            withAsterisk
            label="Type d'event"
            style={{marginLeft: "3rem"}}
            description="Entrez le type de votre event"
            value={event.type}
            data={[
              { value: 'ViewPage', label: 'View Page' },
              { value: 'Clic', label: 'Clic' },
              { value: 'Form', label: 'Form' },
            ]}
             {...form.getInputProps("type")}
            /> */}
              <Select
                withAsterisk
                label="Priorité"
                style={{ marginLeft: "3rem" }}
                description="Priorité de votre event"
                value={event.priority}
                data={[
                  { value: "Faible", label: "Faible" },
                  { value: "Moyenne", label: "Moyenne" },
                  { value: "Haute", label: "Haute" },
                ]}
                {...form.getInputProps("priority")}
              />
            </div>
            <div className="input-container">
              <TextInput
                withAsterisk
                label="Déclencheur"
                value={event.trigger}
                description="Entrez le nom de votre Déclencheur"
                {...form.getInputProps("trigger")}
              />
              <Select
                withAsterisk
                label="Statut event"
                style={{ marginLeft: "3rem" }}
                description="Entrez le statut de votre event"
                value={event.status}
                data={[
                  { value: "À configurer", label: "À configurer" },
                  { value: "À implementer", label: "À implementer" },
                  { value: "Implémenté", label: "Implémenté" },
                  { value: "À recetter", label: "À recetter" },
                  { value: "Recetté", label: "Recetté" },
                  { value: "Annulé", label: "Annulé" },
                ]}
                {...form.getInputProps("status")}
              />
            </div>
            <Textarea
              placeholder="Description de l'evenement"
              label="Description"
              value={event.description}
              {...form.getInputProps("description")}
              style={{ maxWidth: "36.2rem" }}
            />
          </div>

          {/* {loading ? (
            <div>Loading...</div>
        ) : (
            <div>
                {parameters.map(parameter => (
                    <div key={parameter.id}>
                        <p>Parameter Name: {parameter.parameterName}</p>
                        <p>Datatype: {parameter.datatype}</p>
                        <p>Value: {parameter.value}</p>
                        <p>Parameter Comments: {parameter.parameterComments}</p>
                    </div>
                ))}
            </div>
        )}      */}
          <div className="left-2">
            <h3>2. Paramètres associés à l’event</h3>
            <h4>Assigner des paramètres a mon projet</h4>
          </div>
          <div className="right-2">
            <div className="label-container">
              <label style={{ marginRight: "1.5rem" }}>Nom du paramètre</label>
              <label style={{ marginRight: "3rem" }}>Type de données</label>
              <label style={{ marginRight: "1.5rem" }}>Valeur par défaut</label>
              <label>Commentaire</label>
            </div>
            {form.values.parameters.map((parameter, index) => (
              <div className="parameter-container" key={index}>
                <TextInput
                  withAsterisk
                  // description={`${index + 1}`}
                  style={{ marginRight: "1.5rem" }}
                  value={parameter.parameterName}
                  {...form.getInputProps(`parameters.${index}.parameterName`)}
                />
                <Select
                  withAsterisk
                  // description="Entrez le type de données de votre paramètre"
                  style={{ marginRight: "1rem" }}
                  data={[
                    { value: "string", label: "String" },
                    { value: "integer", label: "Number" },
                    { value: "boolean", label: "Boolean" },
                  ]}
                  {...form.getInputProps(`parameters.${index}.datatype`)}
                  value={parameter.datatype}
                  onChange={(value) => {
                    form.setFieldValue(`parameters.${index}.datatype`, value);
                    let defaultValue;
                    switch (value) {
                      case "string":
                        defaultValue = "^[(a-zA-Z0-9-_, )+]*$";
                        break;
                      case "integer":
                        defaultValue = "^([0-9\\s,-])*$";
                        break;
                      case "boolean":
                        defaultValue = "^(true|false)$";
                        break;
                      default:
                        defaultValue = "";
                    }
                    form.setFieldValue(
                      `parameters.${index}.value`,
                      defaultValue
                    );
                  }}
                />
                <img
                  src={right}
                  style={{ marginBottom: "0.85rem" }}
                  alt="arrow-right"
                />
                <TextInput
                  withAsterisk
                  style={{ marginLeft: "1rem" }}
                  // description="Entrez la valeur par défaut"
                  value={parameter.value}
                  {...form.getInputProps(`parameters.${index}.value`)}
                />
                <TextInput
                  // description="Entrez votre commentaire"
                  value={parameter.parameterComments}
                  style={{ marginLeft: "1.5rem" }}
                  {...form.getInputProps(
                    `parameters.${index}.parameterComments`
                  )}
                />
                <div className="container-cross" style={{ marginLeft: "10px" }}>
                  <img
                    src={cross}
                    alt="delete"
                    type="button"
                    style={{ cursor: "pointer", marginBottom: "0.85rem" }}
                    onClick={() => openDeleteModal(index)}
                  />
                </div>
              </div>
            ))}
            <div className="container-add-btn">
              <img
                src={add}
                alt="add"
                type="button"
                style={{ cursor: "pointer", marginTop: "20px" }}
                onClick={addParameter}
              />
            </div>
          </div>
          <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title="Confirmer la suppression"
          >
            <p>
              Êtes-vous sûr de vouloir supprimer ce paramètre ? Cette action est
              irréversible.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Button
                variant="outline"
                color="gray"
                onClick={() => setOpened(false)}
              >
                Annuler
              </Button>
              <Button color="red" onClick={confirmDeleteParameter}>
                Supprimer
              </Button>
            </div>
          </Modal>
        </div>
        <div className="left-2">
          <h3>3. Configuration de l’event</h3>
          <h4>Configurer et ajouter des screenshots à votre event</h4>
        </div>
        <div className="container-config">
          <div className="input-container">
            <TextInput
              withAsterisk
              label="Sur quel URL peut-on tester le code ?"
              value={event.url_test}
              //description="Entrez le nom de votre Déclencheur"
              {...form.getInputProps("url_test")}
              style={{ width: "26.438rem" }}
            />
            <TextInput
              withAsterisk
              style={{ marginLeft: "3rem", width: "26.438rem" }}
              label="Sur quel page/template le datalayer doit il être implementé ?"
              value={event.page_datalayer}
              //description="Entrez le nom de votre Déclencheur"
              {...form.getInputProps("page_datalayer")}
            />
          </div>
          <div className="div-comment">
            <Textarea
              placeholder="Ajouter un commentaire"
              label="Commentaire"
              value={event.comments}
              {...form.getInputProps("comments")}
            />
          </div>
          <div>
            <h3 style={{ marginTop: "2rem" }}>Ajouter un screenshot</h3>
            <Dropzone
              onDrop={setFiles}
              accept={IMAGE_MIME_TYPE}
              maxSize={5242880}
              sx={(theme) => ({
                minHeight: 120,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px dashed #373F4F",
                backgroundColor: "#222838",
                maxWidth: "56rem",

                "&[data-accept]": {
                  color: theme.white,
                  backgroundColor: theme.colors.blue[6],
                },

                "&[data-reject]": {
                  color: theme.white,
                  backgroundColor: theme.colors.red[6],
                },
              })}
            >
              <Group
                position="center"
                spacing="xl"
                style={{ minHeight: 220, pointerEvents: "none" }}
              >
                <Dropzone.Accept>
                  {/* <IconUpload
            size={50}
            stroke={1.5}
            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
          /> */}
                </Dropzone.Accept>
                <Dropzone.Reject>
                  {/* <IconX
            size={50}
            stroke={1.5}
            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
          /> */}
                </Dropzone.Reject>
                <Dropzone.Idle>
                  {/* <IconPhoto size={50} stroke={1.5} /> */}
                </Dropzone.Idle>

                <div style={{ background: "#222838" }}>
                  <Text size="ms" inline>
                    Faites glisser les images ici ou cliquez pour sélectionner
                    vos images
                  </Text>
                  <Text size="sm" color="dimmed" inline mt={7}>
                    Joignez autant de fichiers que vous le souhaitez, chaque
                    fichier ne doit pas dépasser 5 Mo
                  </Text>
                </div>
              </Group>
            </Dropzone>
            <SimpleGrid mt={previews.length > 0 ? "xl" : 0}>
              <div className="preview">{previews}</div>
            </SimpleGrid>
          </div>

          {screenshots.length > 0 && (
            <>
              <h3 className="title-screenshot">Mes Screenshots</h3>
              <div className="container-screenshots">
                {screenshots.map((screenshot, index) => (
                  <div key={index} className="screenshot">
                    <img
                      src={close}
                      alt="close"
                      className="close-icon"
                      onClick={() => openDeleteScreenshotModal(index)}
                    />
                    <Image
                      src={screenshot.file_path}
                      alt={`screenshot-${index}`}
                    />
                  </div>
                ))}
              </div>
            </>
          )}

          <Modal
            opened={opened && screenshotToDelete !== null}
            onClose={() => setOpened(false)}
            title="Confirmer la suppression"
          >
            <p>
              Êtes-vous sûr de vouloir supprimer ce screenshot ? Cette action
              est irréversible.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Button
                variant="outline"
                color="gray"
                onClick={() => setOpened(false)}
              >
                Annuler
              </Button>
              <Button color="red" onClick={confirmDeleteScreenshot}>
                Supprimer
              </Button>
            </div>
          </Modal>
        </div>
        <div className="container-datalayer">
          <div className="title-datalayer-btn">
            <div>
              <h3>4. Datalayer</h3>
              <h4>Générer votre DataLayer</h4>
            </div>
            <Button
              type="button"
              size="xs"
              variant="gradient"
              gradient={{ from: "indigo", to: "cyan" }}
              onClick={generateDataLayerCode}
            >
              Générer le datalayer
            </Button>
          </div>
          <Textarea
            value={datalayer} // Utilisation de l'état local datalayer
            onChange={handleDatalayerChange} // Permet de modifier le contenu
          />
          {isManualEdit && (
            <Button
              size="xs"
              variant="gradient"
              gradient={{ from: "indigo", to: "cyan" }}
              style={{ marginTop: "1rem" }}
              onClick={resetToGeneratedDatalayer}
            >
              Réinitialiser au DataLayer généré précédemment
            </Button>
          )}
        </div>
        <div className="div-button">
          <Button color="indigo" type="submit">
            Configurer mon event
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ConfigEvent;
