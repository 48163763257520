import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";

const RequireAuth = () => {
  const { authState } = useContext(AuthContext);
  const location = useLocation();
  //console.log(authState)
  return authState.status ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireAuth;
