import React, { useState, useEffect } from "react";
import instance from "../axios/global";
import { useNavigate } from "react-router-dom";
import {
  TextInput,
  Textarea,
  Chip,
  Select,
  Button,
  Box,
  Group,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { toast } from "sonner";
import cross from "../assets/cross.svg";
import add from "../assets/add.svg";

const CreateProject = () => {
  const [allGenericEvents, setAllGenericEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedChips, setSelectedChips] = useState([]);
  const [customFields, setCustomFields] = useState([{ key: "", value: "" }]);
  const navigate = useNavigate();

  const isValidUrl = (url) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(url);
  };

  const form = useForm({
    initialValues: {
      client: "",
      projectName: "",
      platform_type: "",
      projectUrl: "",
      projectUrlPreProd: "",
      activity_area: "",
      country: "",
      encapsulator: "",
      technology: "",
      analytics_platform: "",
      information: "",
      custom_fields: [],
    },
    validate: {
      client: (value) =>
        value.length === 0 ? "Veuillez remplir ce champs" : null,
      projectName: (value) =>
        value.length === 0 ? "Veuillez remplir ce champs" : null,
      platform_type: (value) =>
        value.length === 0 ? "Veuillez remplir ce champs" : null,
      country: (value) =>
        value.length === 0 ? "Veuillez remplir ce champs" : null,
      technology: (value) =>
        value.length === 0 ? "Veuillez remplir ce champs" : null,
      analytics_platform: (value) =>
        value.length === 0 ? "Veuillez remplir ce champs" : null,
      projectUrl: (value) => {
        if (value.length === 0) {
          return "Veuillez remplir ce champs";
        } else {
          return !isValidUrl(value) ? "Veuillez entrer une URL valide" : null;
        }
      },
      projectUrlPreProd: (value) => {
        if (value.length === 0) {
          return null; // Pas d'erreur si vide
        }
        return !isValidUrl(value) ? "Veuillez entrer une URL valide" : null; // Valide seulement si non vide
      },
      encapsulator: (value) =>
        value.length === 0 ? "Veuillez remplir ce champs" : null,
      activity_area: (value) =>
        value.length === 0 ? "Veuillez remplir ce champs" : null,
    },
  });

  useEffect(() => {
    const fetchGenericEvents = async () => {
      setLoading(true);
      try {
        const res = await instance.get("/event/generic");
        setAllGenericEvents(res?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetchGenericEvents();
  }, []);

  const handleChipClick = (eventId) => {
    setSelectedChips((prevSelectedChips) => {
      if (prevSelectedChips.includes(eventId)) {
        return prevSelectedChips.filter((id) => id !== eventId);
      } else {
        return [...prevSelectedChips, eventId];
      }
    });
  };

  // Ajouter un nouveau champ personnalisé
  const addCustomField = () => {
    setCustomFields([...customFields, { key: "", value: "" }]);
  };

  // Supprimer un champ personnalisé
  const removeCustomField = (index) => {
    setCustomFields(customFields.filter((_, i) => i !== index));
  };

  // Gérer le changement dans les champs personnalisés
  const handleCustomFieldChange = (index, field, value) => {
    const newFields = [...customFields];
    newFields[index][field] = value;
    setCustomFields(newFields);
  };

  const handleSubmitProjectData = async () => {
    const customFieldsJson = JSON.stringify(
      customFields.filter((field) => field.key && field.value)
    );
    try {
      if (form.validate().hasErrors) {
        return;
      }

      const projectResponse = await instance.post(`/project/create`, {
        client: form.values.client,
        projectName: form.values.projectName,
        projectUrl: form.values.projectUrl,
        projectUrlPreProd: form.values.projectUrlPreProd,
        country: form.values.country,
        technology: form.values.technology,
        analytics_platform: form.values.analytics_platform,
        encapsulator: form.values.encapsulator,
        activity_area: form.values.activity_area,
        platform_type: form.values.platform_type,
        status: "À configurer",
        information: form.values.information,
        custom_fields: (form.values.custom_fields = customFieldsJson),
      });

      const projectId = projectResponse.data.projectId;

      for (const selectedChipId of selectedChips) {
        try {
          const addEvent = await instance.post(
            `/event/duplicate/${selectedChipId}`,
            {
              projectId: projectId,
            }
          );
        } catch (error) {
          toast.error(
            `Erreur lors de l'ajout de l'événement ${selectedChipId}`
          );
        }
      }

      form.reset();
      navigate(`/home`);
      toast.success("Projet créé avec succès !");
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Erreur lors de la création du projet.";
      toast.error(errorMessage);
    }
  };

  const groupEventsByTag = (events) => {
    return events.reduce((groupedEvents, event) => {
      const tags = event.tags ? event.tags.split(",") : ["Sans tag"];
      tags.forEach((tag) => {
        if (!groupedEvents[tag]) {
          groupedEvents[tag] = [];
        }
        groupedEvents[tag].push(event);
      });
      return groupedEvents;
    }, {});
  };

  const groupedEvents = groupEventsByTag(allGenericEvents);

  return (
    <div className="create-project">
      <div className="titles">
        <h1>Créer un nouveau projet</h1>
        <h2>
          Entrez les données nécessaires à la création d'un nouveau projet
        </h2>
      </div>
      <form onSubmit={form.onSubmit(handleSubmitProjectData)}>
        <div className="container-params">
          <div className="left">
            <h3>1. Paramètres</h3>
            <h4>Choix des paramètres de mon projet</h4>
          </div>
          <div className="right">
            <div className="input-container">
              <TextInput
                withAsterisk
                label="Nom du client"
                placeholder="Entrez le nom de votre client"
                {...form.getInputProps("client")}
              />
              <TextInput
                withAsterisk
                label="Nom du projet"
                placeholder="Entrez le nom du projet"
                {...form.getInputProps("projectName")}
              />
            </div>
            <div className="input-container">
              <TextInput
                withAsterisk
                label="Url du projet"
                placeholder="Entrez l'Url du projet'"
                {...form.getInputProps("projectUrl")}
              />
              <TextInput
                label="Url de la preprod projet"
                placeholder="Entrez l'Url de la preprod projet'"
                {...form.getInputProps("projectUrlPreProd")}
              />
            </div>
            <div className="input-container">
              <Select
                withAsterisk
                label="Pays"
                searchable
                placeholder="Entrez le Pays"
                data={[
                  { value: "france", label: "France" },
                  { value: "espagne", label: "Espagne" },
                  { value: "etats-unis", label: "États-Unis" },
                  { value: "royaume-Uni", label: "Royaume-Uni" },
                  { value: "canada", label: "Canada" },
                  { value: "allemagne", label: "Allemagne" },
                  { value: "nouvelle-zelande", label: "Nouvelle Zélande" },
                ]}
                {...form.getInputProps("country")}
              />
              <Select
                withAsterisk
                label="Technologie utilisée"
                placeholder="Entrez la technologie utilisée"
                searchable
                data={[
                  { value: "custom", label: "Custom" },
                  { value: "shopify", label: "Shopify" },
                  { value: "wordpress", label: "WordPress" },
                ]}
                {...form.getInputProps("technology")}
              />
            </div>
            <div className="input-container">
              <Select
                withAsterisk
                label="Tag Manager"
                placeholder="Entrez vos Tag Manager"
                searchable
                data={[
                  { value: "custom", label: "Custom" },
                  { value: "gtm", label: "GTM" },
                  { value: "tag_commander", label: "Tag Commander" },
                  { value: "tealium", label: "Tealium" },
                ]}
                {...form.getInputProps("encapsulator")}
              />
              <Select
                withAsterisk
                label="Type de plateforme"
                placeholder="Entrez le type de plateforme"
                searchable
                data={[
                  { value: "android", label: "Android" },
                  { value: "ios", label: "iOS" },
                  { value: "web", label: "Web" },
                ]}
                {...form.getInputProps("platform_type")}
              />
            </div>
            <div className="input-container">
              <Select
                withAsterisk
                label="Plateforme analytics"
                searchable
                placeholder="Entrez le nom de votre Plateforme analytics"
                data={[
                  { value: "AdobeAnalytics", label: "Adobe Analytics" },
                  { value: "GoogleAnalytics", label: "Google Analytics" },
                  { value: "PianoAnalytics", label: "Piano Analytics" },
                  { value: "PiwikPro", label: "Piwik Pro" },
                  { value: "custom", label: "Custom" },
                ]}
                {...form.getInputProps("analytics_platform")}
              />
              <Select
                withAsterisk
                label="Secteur d'activité"
                placeholder="Entrez le Secteur d'activité"
                searchable
                data={[
                  { value: "alimentation", label: "Alimentation" },
                  { value: "automobile", label: "Automobile" },
                  { value: "bancassurance", label: "Bancassurance" },
                  { value: "industrie", label: "Industrie" },
                  {
                    value: "grande distribution",
                    label: "Grande Distribution",
                  },
                  { value: "retail", label: "Retail" },
                  { value: "media", label: "Média" },
                ]}
                {...form.getInputProps("activity_area")}
              />
            </div>
            <Textarea
              label="Informations complémentaire"
              autosize
              minRows={3}
              placeholder="Entrez les information complémentaire"
              {...form.getInputProps("information")}
            />
            <Box mt="md">
              <h3 style={{ marginBottom: "15px" }}>Champs personnalisés</h3>
              {customFields.map((field, index) => (
                <div
                  className="custom-fields-container"
                  key={index}
                  mt="xs"
                  position="apart"
                >
                  <div className="name-cross">
                    <TextInput
                      placeholder="Nom du champ"
                      value={field.key}
                      onChange={(event) =>
                        handleCustomFieldChange(
                          index,
                          "key",
                          event.currentTarget.value
                        )
                      }
                    />
                    <img
                      src={cross}
                      alt="delete"
                      type="button"
                      style={{ cursor: "pointer" }}
                      onClick={() => removeCustomField(index)}
                    />
                  </div>
                  <Textarea
                    autosize
                    placeholder="Valeur du champ"
                    value={field.value}
                    onChange={(event) =>
                      handleCustomFieldChange(
                        index,
                        "value",
                        event.currentTarget.value
                      )
                    }
                  />
                </div>
              ))}
              <div className="container-add-btn">
                <img
                  src={add}
                  alt="add"
                  type="button"
                  style={{ cursor: "pointer", marginTop: "20px" }}
                  onClick={addCustomField}
                />
              </div>
            </Box>
          </div>
        </div>

        <div className="container-params">
          <div className="left">
            <h3>2. Choix des évenements</h3>
            <h4>Assigner des evenements a mon projet</h4>
          </div>
          <div className="right">
            <h3>Standard</h3>
            <div className="chip-container">
              {groupedEvents["standard"]?.map((event) => (
                <Chip
                  className="chip"
                  style={{ width: "120px" }}
                  defaultChecked
                  radius="sm"
                  size="sm"
                  color="#5A63D3"
                  key={event.id}
                  value={event.id}
                  checked={selectedChips.includes(event.id)}
                  onChange={() => handleChipClick(event.id)}
                >
                  {event.name}
                </Chip>
              ))}
            </div>
            <h3 className="title-tag">E-Commerce</h3>
            <div className="chip-container">
              {groupedEvents["e-commerce"]?.map((event) => (
                <Chip
                  className="chip"
                  style={{ width: "120px" }}
                  defaultChecked
                  radius="sm"
                  size="sm"
                  color="#5A63D3"
                  key={event.id}
                  value={event.id}
                  checked={selectedChips.includes(event.id)}
                  onChange={() => handleChipClick(event.id)}
                >
                  {event.name}
                </Chip>
              ))}
            </div>
            <h3 className="title-tag">Conversion</h3>
            <div className="chip-container">
              {groupedEvents["conversion"]?.map((event) => (
                <Chip
                  className="chip"
                  style={{ width: "120px" }}
                  defaultChecked
                  radius="sm"
                  size="sm"
                  color="#5A63D3"
                  key={event.id}
                  value={event.id}
                  checked={selectedChips.includes(event.id)}
                  onChange={() => handleChipClick(event.id)}
                >
                  {event.name}
                </Chip>
              ))}
            </div>
            <h3 className="title-tag">Interaction</h3>
            <div className="chip-container">
              {groupedEvents["interaction"]?.map((event) => (
                <Chip
                  className="chip"
                  style={{ width: "120px" }}
                  defaultChecked
                  radius="sm"
                  size="sm"
                  color="#5A63D3"
                  key={event.id}
                  value={event.id}
                  checked={selectedChips.includes(event.id)}
                  onChange={() => handleChipClick(event.id)}
                >
                  {event.name}
                </Chip>
              ))}
            </div>
            <div className="div-button">
              <Button variant="filled" type="submit">
                Créer un nouveau projet
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateProject;
