import React, { createContext, useState, useEffect } from "react";
import instance from "../axios/global";
import { useNavigate } from "react-router-dom";
import { Loader } from "@mantine/core";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    role: "",
    id: "",
    status: false,
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        setAuthState({ ...authState, status: false });
        setLoading(false);
        return;
      }

      try {
        const res = await instance.get("/user/auth", {
          headers: {
            accessToken: token,
          },
        });

        if (res.data.error) {
          // Si le token est invalide ou expiré
          localStorage.removeItem("accessToken");
          setAuthState({ ...authState, status: false });
          navigate("/");
        } else {
          const userData = {
            firstname: res.data.firstName,
            lastname: res.data.lastName,
            email: res.data.email,
            role: res.data.role,
            id: res.data.id,
            status: true,
          };
          localStorage.setItem("user", JSON.stringify(userData));
          setAuthState(userData);
        }
      } catch (error) {
        console.error("Authentication error:", error);
        setAuthState({ ...authState, status: false });
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []); // Exécuter une seule fois au montage

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {loading ? <div></div> : children}
    </AuthContext.Provider>
  );
};
