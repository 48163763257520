import React, { useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/logo-autotrack.svg";
import sidebarclose from "../assets/sidebar-close.svg";
import sidebaropen from "../assets/sidebar-open.svg";
import home from "../assets/home.svg";
import logout from "../assets/logout.svg";
import settings from "../assets/settings.svg";
import event from "../assets/event.svg";
import create from "../assets/create.svg";
import help from "../assets/help.svg";
import createEvent from "../assets/create-event.svg";
import tracking from "../assets/tracking.svg";
import { AuthContext } from "../context/authContext";


const Sidebar = ({ selectedProject, onHomepageClick }) => {
  const [arrow, setArrow] = useState(true);
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("selectedProject"); 
    setAuthState({
      firstname: "",
      lastname: "",
      role: "",
      email: "",
      id: "",
      status: false,
    });
    navigate("/");
  };

  return (
    <aside className={arrow ? "aside-navsidebar" : "aside-close"}>
      <div className="arrow">
        {arrow ? (
          <img
            src={sidebaropen}
            alt={sidebaropen}
            onClick={() => setArrow(!arrow)}
            className="arrow-sidebar"
          />
        ) : (
          <img
            src={sidebarclose}
            alt={sidebarclose}
            onClick={() => setArrow(!arrow)}
            className="arrow-sidebar"
          />
        )}
      </div>
      <div className="nav-logo" onClick={onHomepageClick}>
        {" "}
        <img onClick={() => navigate("/home")} style={{width: "28px"}}src={logo} alt="logo-S.I" />
        <h1 className={arrow ? "" : "display-none"}>
          Auto<span>Track</span>
        </h1>
      </div>
      
      
      <nav className="nav-sidebar">
      {authState.status === true && (
         <>
         <div className="line"></div>
      <div className="main">MAIN</div>
        <NavLink to="/home" className="hover-link" onClick={onHomepageClick}>
          <img src={home} alt="home" />
          <span className={arrow ? "nav-links" : "display-none"}>Home</span>
        </NavLink>
        <NavLink to={`/creer-projet`} className="hover-link">
          <img src={create} alt="create-event" />
          <span className={arrow ? "nav-links" : "display-none"}>
            Creer projet
          </span>
        </NavLink>
        </>
      )}
        {/* {authState.role === "admin" && (
          <NavLink to="/events-generique" className="hover-link" onClick={onHomepageClick}>
            <img src={event} alt="listevent" />
            <span className={arrow ? "nav-links" : "display-none"}>Events générique</span>
          </NavLink>
        )} */}
        {authState.role === "admin" && (
        <NavLink to="/events-generique" className="hover-link">
          <img src={event} alt="listevent" />
          <span className={arrow ? "nav-links" : "display-none"}>Events générique</span>
        </NavLink>
        )}
        
        <div className="line"></div>
        {authState.status && selectedProject && (
    <>
      <div className="main">SETTINGS</div>
      <NavLink to={`/project/${selectedProject}`} className="hover-link">
  <img src={createEvent} alt="project" />
  <span className={arrow ? "nav-links" : "display-none"}>Projet</span>
</NavLink>

      <NavLink to={`/plan-tracking/${selectedProject}`} className="hover-link">
        <img src={tracking} alt="settings" />
        <span className={arrow ? "nav-links" : "display-none"}>Plan de tracking</span>
      </NavLink>
      <NavLink to={`/parametres/${selectedProject}`} className="hover-link">
        <img src={settings} alt="settings" />
        <span className={arrow ? "nav-links" : "display-none"}>Paramètres</span>
      </NavLink>
    </>
  )}

        {/* <NavLink to="/documentation" className="hover-link">
          <img src={help} alt="documentation" />
          <span className={arrow ? "nav-links" : "display-none"}>
            Documentation
          </span>
        </NavLink> */}
      </nav>
      {authState.status === true && (
        <>
      <footer>
        <div className="logout" onClick={handleLogout}>
          <img src={logout} alt="logout" />
          <span className={arrow ? "nav-links" : "display-none"}>Logout</span>
        </div>
 
      </footer>
      </>
  )}
    </aside>
  );
};

export default Sidebar;
