// App.js
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Accueil from "./pages/Accueil";
import Login from "./pages/Login";
import PublicTrackingPlan from "./pages/PublicTrackingPlan";
import { AuthProvider } from "./context/authContext";
import { Toaster } from "sonner";
import RequireAuth from "./hooks/RequireAuth";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {/* Routes publiques */}
          <Route path="/" element={<Login />} />
          <Route
            path="/public-plan-tracking/:id"
            element={<PublicTrackingPlan />}
          />

          {/* Routes protégées */}
          <Route element={<RequireAuth />}>
            <Route path="/*" element={<Accueil />} />
          </Route>
        </Routes>
        <Toaster />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
